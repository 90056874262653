import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';

import { DeviceTypesList } from './list';
import { DeviceTypesCreate, DeviceTypesEdit } from './edit';


export default {
    icon: DeviceUnknownIcon,
    list: DeviceTypesList,
    create: DeviceTypesCreate,
    edit: DeviceTypesEdit,
};
