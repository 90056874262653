import React from "react";
import { Fragment } from "react";
import { List, BulkDeleteButton } from "react-admin";

const PessimisticBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteButton undoable={false} {...props} />
    </Fragment>
);

export const PessimisticList = ({ children, ...props }) => (
    <List bulkActionButtons={<PessimisticBulkActionButtons />} {...props}>
        {children}
    </List>
);
