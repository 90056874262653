export const apiUrl = () => {
    // Process environmental variable used in local proxy-less environment.
    // One case of such environmental is local development.
    const processApiUrl = process.env.REACT_APP_BACKEND_API_URL
    if (processApiUrl) {
        return processApiUrl
    }

    // Default proxied API prefix.
    return "/api"
}
