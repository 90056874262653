import React from "react";
import {
    Datagrid,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    TextField,
} from "react-admin";
import { PessimisticList } from "components/pessimistic_list";
import { SearchFilter } from "components/search_filter";

const MyFilter = (props) => (
    <SearchFilter {...props}>
        <ReferenceInput source="language_id" reference="languages">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="installation_id" reference="installations">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="customization_id" reference="customizations">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="device_type_id" reference="device_types">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </SearchFilter>
);

export const TranslationsGrid = (props) => (
    <Datagrid rowClick="edit" {...props}>
        <TextField source="id" />
        {/* <TextField source="phrase.phrase" />
                    <TextField source="language.name" /> */}
        <ReferenceField
            label="Phrase"
            source="phrase_id"
            reference="phrases"
            allowEmpty={true}
        >
            <TextField source="phrase" />
        </ReferenceField>
        <ReferenceField
            source="language_id"
            reference="languages"
            allowEmpty={true}
        >
            <TextField source="name" />
        </ReferenceField>
        <TextField source="translation" />
        {/* <TextField source="customization.name" />
                    <TextField source="installation.name" />
                    <TextField source="device_type.name" /> */}
        <ReferenceField
            source="installation_id"
            reference="installations"
            allowEmpty={true}
        >
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField
            source="customization_id"
            reference="customizations"
            allowEmpty={true}
        >
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField
            source="device_type_id"
            reference="device_types"
            allowEmpty={true}
        >
            <TextField source="name" />
        </ReferenceField>
    </Datagrid>
);

export const TranslationsList = (props) => (
    <PessimisticList {...props} filters={<MyFilter />}>
        <TranslationsGrid />
    </PessimisticList>
);
