import React from "react";
import {
    Create,
    FormTab,
    ReferenceManyField,
    SimpleForm,
    TabbedForm,
    TextInput,
    required,
} from "react-admin";
import { CustomizationsGrid } from "resources/customizations/list";
import { PessimisticEdit } from "components/pessimistic_edit";

const InstallationForm = (props) => (
    <>
        <TextInput
            source="id"
            {...(props.record.id ? { disabled: true } : {})}
        />
        <br />
        <TextInput source="name" validate={required()} />
    </>
);

export const InstallationsCreate = (props) => (
    <Create {...props}>
        <SimpleForm {...props}>
            <InstallationForm {...props} />
        </SimpleForm>
    </Create>
);

export const InstallationsEdit = (props) => (
    <PessimisticEdit {...props}>
        <TabbedForm {...props}>
            <FormTab label="General">
                <InstallationForm {...props} />
            </FormTab>
            <FormTab label="Customizations">
                <ReferenceManyField
                    fullWidth
                    reference="customizations"
                    target="installation_id"
                    addLabel={false}
                >
                    <CustomizationsGrid />
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </PessimisticEdit>
);
