import React from "react";
import {
    TextField,
    Datagrid,
    Responsive,
    SimpleList,
    SingleFieldList,
    ReferenceArrayField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from "react-admin";
import { SearchFilter } from "components/search_filter";
import { ColouredChipField } from "components/coloured_chip_field";
import TruncatedTextField from "components/truncated_text_field";
import { PessimisticList } from "components/pessimistic_list";

const PhrasesCustomFilter = (props) => (
    <SearchFilter {...props}>
        <ReferenceArrayInput
            label="Tags"
            source="`translation_phrase_tag_phrases`.`tag_id`"
            reference="phrase_tags"
        >
            <AutocompleteArrayInput optionText="label" />
        </ReferenceArrayInput>
    </SearchFilter>
);

export const PhrasesGrid = (props) => {
    return (
        <Datagrid rowClick="edit" {...props}>
            <TextField source="id" />
            <TruncatedTextField source="phrase" maxLength={50} />
            <ReferenceArrayField
                label="Tags"
                reference="phrase_tags"
                source="tags"
            >
                <SingleFieldList>
                    <ColouredChipField source="label" colourSource="colour" />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    );
};

export const PhrasesList = (props) => (
    <PessimisticList
        {...props}
        filters={<PhrasesCustomFilter />}
        sort={{ field: "phrase", order: "ASC" }}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={(record) => record.phrase}
                />
            }
            medium={<PhrasesGrid {...props} />}
        />
    </PessimisticList>
);
