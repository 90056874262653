import ViewListIcon from "@material-ui/icons/ViewList";

import { TranslationsList } from "./list";
import { TranslationsCreate, TranslationsEdit } from "./edit";

export default {
    icon: ViewListIcon,
    list: TranslationsList,
    create: TranslationsCreate,
    edit: TranslationsEdit
};
