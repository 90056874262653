import ViewListIcon from "@material-ui/icons/ViewList";

import { FeatureFlagRulesList } from "./list";
import { FeatureFlagRulesCreate, FeatureFlagRulesEdit } from "./edit";

export default {
    icon: ViewListIcon,
    list: FeatureFlagRulesList,
    create: FeatureFlagRulesCreate,
    edit: FeatureFlagRulesEdit,
};
