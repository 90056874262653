import LabelIcon from "@material-ui/icons/Label";

import { FlagTagsList } from "./list";
import { FlagTagsCreate, FlagTagsEdit } from "./edit";

export default {
    icon: LabelIcon,
    list: FlagTagsList,
    create: FlagTagsCreate,
    edit: FlagTagsEdit,
};
