import React, { useState } from "react";

import { useInput } from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { StyledDropzone } from "components/styled_dropzone";

const useStyles = makeStyles(() => ({
    hidden: {
        display: "none",
    },
    imagePreview: {
        height: "20vh",
        padding: "0.5em",
    },
    imageInput: {
        padding: "0.5em",
    },
    imageContainerImage: {
        width: "100%",
        maxHeight: "100%",
        borderWidth: 2,
        borderColor: "#999999",
        borderStyle: "dashed",
        borderRadius: 2,
    },
}));

/**
 * This component renders the current image of a record, if it has one. On top of this, it
 * renders a small upload button at the bottom right corner of the image in order to change
 * the record's image. For this, the new image gets loaded with the ReactCrop component.
 *
 * @param record is the record from the datagrid.
 * @param source is the source name for the image.
 * @param props are the remaining parent-level properties,
 */
export const Base64ImageInput = ({ record, source, ...props }) => {
    // const classes = useStyles();
    const {
        input: { value, onChange },
    } = useInput({ record, source, ...props });

    const [imageDataURL, setImageDataUrl] = useState(value);

    const onSelectFile = (files) => {
        if (files && files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImageDataUrl(reader.result);
                onChange(reader.result);
            });
            reader.readAsDataURL(files[0]);
        }
    };

    return (
        <>
            <StyledDropzone onDrop={onSelectFile} />
            <ImagePreview src={imageDataURL} />
        </>
    );
};

Base64ImageInput.defaultProps = {
    addLabel: true,
};

const ImagePreview = ({ src }) => {
    const classes = useStyles();

    return (
        <div>
            <Typography>
                <b>Preview</b>
            </Typography>
            {src ? (
                <img
                    src={src}
                    alt="Preview"
                    className={classes.imageContainerImage}
                />
            ) : (
                <Typography>No image</Typography>
            )}
        </div>
    );
};

ImagePreview.defaultProps = {
    src: "",
};
