import BrushIcon from '@material-ui/icons/Brush';

import { CustomizationsList } from './list';
import { CustomizationsCreate, CustomizationsEdit } from './edit';


export default {
    icon: BrushIcon,
    list: CustomizationsList,
    create: CustomizationsCreate,
    edit: CustomizationsEdit,
};
