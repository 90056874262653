import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { PessimisticEdit } from "components/pessimistic_edit";

const DeviceTypeForm = (props) => (
    <SimpleForm {...props}>
        <TextInput
            source="id"
            {...(props.record.id ? { disabled: true } : {})}
        />
        <TextInput source="name" validate={required()} />
    </SimpleForm>
);

export const DeviceTypesCreate = (props) => (
    <Create {...props}>
        <DeviceTypeForm {...props} />
    </Create>
);

export const DeviceTypesEdit = (props) => (
    <PessimisticEdit {...props}>
        <DeviceTypeForm {...props} />
    </PessimisticEdit>
);
