import { stringify } from "query-string";
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from "ra-core";

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const adminFilterToWhereCondition = (filter) => {
        const flattenObjectKeys = (obj, keyPrefix) => {
            var newObject = {};
            for (var key in obj) {
                const val = obj[key];
                if (!!keyPrefix) {
                    key = `${keyPrefix}.${key}`;
                }

                if (typeof val !== "object" || Array.isArray(val)) {
                    newObject[key] = val;
                    continue;
                }

                newObject = {
                    ...newObject,
                    ...flattenObjectKeys(val, key),
                };
            }

            return newObject;
        };

        // params.filter is k-v object, where k is property name and v is value of the
        let w = [];
        const p = flattenObjectKeys(filter);
        for (var _key of Object.keys(p)) {
            const key = _key;
            if (
                !key ||
                key === "undefined" ||
                ["q", "phrase_tags"].filter((x) => x === key).length > 0 ||
                !p[key]
            ) {
                // ignore if null key or null value or key is 'q'/'phrase_tags'
                continue;
            }

            if (Array.isArray(p[key])) {
                w.push({
                    type: "P",
                    where: {
                        field: key,
                        operator: "IN",
                        array_value: p[key],
                    },
                });
            } else {
                w.push({
                    type: "P",
                    where: {
                        field: key,
                        operator: "=",
                        value: p[key],
                    },
                });
            }
        }

        return w;
    };
    const adminSortToOrder = ({ field, order: orderDirection }) => {
        return {
            field: field,
            direction: orderDirection || "asc",
        };
    };
    const adminPaginationTransform = ({ page, perPage }) => {
        return {
            limit: perPage,
            offset: (page - 1) * perPage,
        };
    };

    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = "";
        const options = {};
        console.log(
            "[DATAPROVIDER] converting request to HTTP request",
            type,
            resource,
            params
        );
        switch (type) {
            case GET_LIST: {
                console.log("[DATAPROVIDER][GET_LIST] params", params);
                let w = {};
                let scopeParams = {};
                let extraParams = params.extraParams ? params.extraParams : {};

                if (params?.filter?.q) {
                    extraParams.search = params.filter.q;
                }

                switch (resource) {
                    default: {
                        const { extraFilterParams, scope, ...filter } =
                            params.filter;
                        w = adminFilterToWhereCondition(filter);
                        extraParams = { ...extraParams, ...extraFilterParams };
                        scopeParams = scope;
                    }
                }

                const pagination = adminPaginationTransform(params.pagination);

                const query = {
                    order: JSON.stringify(adminSortToOrder(params.sort)),
                    where: JSON.stringify(w),
                    ...pagination,
                    ...extraParams,
                    ...scopeParams,
                };

                url = `${apiUrl}/${resource}?${stringify(query)}`;

                break;
            }

            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                console.log("[DATAPROVIDER][GET_ONE] url =", url);

                break;

            case GET_MANY: {
                console.log("[DATAPROVIDER][GET_MANY] params", params);
                let adminFilter = {
                    id: params.ids,
                    ...params.filter,
                };

                switch (resource) {
                    case "phrases":
                        adminFilter = {
                            "translation_phrases.id": params.ids,
                            ...params.filter,
                        };
                        break;

                    case "feature_flags":
                        adminFilter = {
                            "feature_flags.id": params.ids,
                            ...params.filter,
                        };
                        break;

                    default:
                }

                const w = adminFilterToWhereCondition(adminFilter);
                const pagination = params.pagination
                    ? adminPaginationTransform(params.pagination)
                    : {};

                const extraParams = params.extraParams
                    ? params.extraParams
                    : {};

                const query = {
                    // filter: JSON.stringify({
                    //     id: params.ids,
                    // }),
                    order: params.sort
                        ? JSON.stringify(adminSortToOrder(params.sort))
                        : undefined,
                    where: JSON.stringify(w),
                    ...pagination,
                    ...extraParams,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }

            case GET_MANY_REFERENCE: {
                console.log(
                    "[DATAPROVIDER][GET_MANY_REFERENCE] params",
                    params
                );
                let w = {};
                let extraParams = params.extraParams ? params.extraParams : {};

                if (params?.filter?.q) {
                    extraParams.search = params.filter.q;
                }

                switch (resource) {
                    default: {
                        const { extraFilterParams, ...filter } = params.filter;
                        w = adminFilterToWhereCondition({
                            ...filter,
                            [params.target]: params.id,
                        });
                        extraParams = { ...extraParams, ...extraFilterParams };
                    }
                }

                const pagination = adminPaginationTransform(params.pagination);

                const query = {
                    order: JSON.stringify(adminSortToOrder(params.sort)),
                    where: JSON.stringify(w),
                    ...pagination,
                    ...extraParams,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;

                break;
            }

            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "PUT";
                options.body = JSON.stringify(params.data);
                break;

            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;

            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;

            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {
            url,
            options,
        };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { json } = response;
        console.log(
            "[DATAPROVIDER] converting http response back",
            json,
            type,
            resource,
            params
        );
        switch (type) {
            case GET_LIST:
            case GET_MANY:
            case GET_MANY_REFERENCE:
                return {
                    data: json.data,
                    total: json["$metadata"].recordsFiltered,
                };
            case CREATE: {
                return json;
            }
            case DELETE_MANY: {
                return json || [];
            }
            default:
                return json;
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return async (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "DELETE",
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then((response) =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};
