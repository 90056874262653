import { regex } from "react-admin";

export const validateColour = regex(
    /(^#([a-zA-Z0-9]{3}){1,2}$)|(^rgb\(([0-9]{1,3},){2}[0-9]{1,3}\))|(^rgba\(([0-9]{1,3},){3}(1|0\.[0-9]+)\))/,
    () => "Must be a valid colour"
);

export const validateICUID = regex(
    /^([a-zA-Z0-9_]+\.)*?([a-zA-Z0-9_]+)$/,
    () => "Must be a valid ICU ID"
);
