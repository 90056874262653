import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
} from "react-admin";
import { Base64ImageInput } from "components/base64_image_input";
import { PessimisticEdit } from "components/pessimistic_edit";

const ImageForm = (props) => (
    <SimpleForm {...props}>
        <TextInput
            source="id"
            validate={required()}
            disabled={props.record.id}
        />
        <TextInput source="description" multiline maxlength={255} />
        <NumberInput source="width" step={1} />
        <NumberInput source="height" step={1} />

        <Base64ImageInput source="default_value" />
    </SimpleForm>
);

export const ImagesCreate = (props) => (
    <Create {...props}>
        <ImageForm {...props} />
    </Create>
);

export const ImagesEdit = (props) => (
    <PessimisticEdit {...props}>
        <ImageForm {...props} />
    </PessimisticEdit>
);
