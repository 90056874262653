import React from "react";
import {
    Create,
    NumberInput,
    SimpleForm,
    TextInput,
    required,
} from "react-admin";
import { PessimisticEdit } from "components/pessimistic_edit";

const LanguageForm = (props) => (
    <SimpleForm {...props}>
        <TextInput
            source="id"
            {...(props.record.id ? { disabled: true } : {})}
        />

        <TextInput source="name" />
        <NumberInput source="plural_forms" step={1} validate={required()} />
        <TextInput source="plural_formula" />
    </SimpleForm>
);

export const LanguagesCreate = (props) => (
    <Create {...props}>
        <LanguageForm {...props} />
    </Create>
);

export const LanguagesEdit = (props) => (
    <PessimisticEdit {...props}>
        <LanguageForm {...props} />
    </PessimisticEdit>
);
