import LabelIcon from "@material-ui/icons/Label";

import { PhraseTagsList } from "./list";
import { PhraseTagsCreate, PhraseTagsEdit } from "./edit";

export default {
    icon: LabelIcon,
    list: PhraseTagsList,
    create: PhraseTagsCreate,
    edit: PhraseTagsEdit,
};
