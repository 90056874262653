import FlagIcon from "@material-ui/icons/Flag";

import { FeatureFlagsList } from "./list";
import { FeatureFlagsCreate, FeatureFlagsEdit } from "./edit";

export default {
    icon: FlagIcon,
    list: FeatureFlagsList,
    create: FeatureFlagsCreate,
    edit: FeatureFlagsEdit,
};
