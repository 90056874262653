import React, { useMemo } from "react";

import { useDropzone } from "react-dropzone";

export const StyledDropzone = (props) => {
    const baseStyle = {
        width: "100%",
        padding: "20px",
        marginBottom: "1em",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .2s ease-in-out",
    };

    const activeStyle = {
        borderColor: "#2196f3",
    };

    const acceptStyle = {
        borderColor: "#00e676",
    };

    const rejectStyle = {
        borderColor: "#ff1744",
    };
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            console.log("[styled-dropzone] dropped file", acceptedFiles);
            props.onDrop(acceptedFiles);
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [
            isDragActive,
            isDragReject,
            isDragAccept,
            acceptStyle,
            activeStyle,
            baseStyle,
            rejectStyle,
        ]
    );

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
    );
};

StyledDropzone.defaultProps = {
    onDrop: () => {},
};
