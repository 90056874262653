import React from "react";
import {
    ArrayInput,
    Create,
    FormTab,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    required,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { Base64ImageInput } from "components/base64_image_input";
import { validateColour } from "utils/validators";
import { PessimisticEdit } from "components/pessimistic_edit";

const CustomizationForm = (props) => {
    console.log("[customization-edit] form func", props);

    return (
        <TabbedForm {...props}>
            <FormTab label="General">
                <TextInput
                    source="id"
                    {...(props.record.id ? { disabled: true } : {})}
                />
                <TextInput source="name" validate={required()} />
                <ReferenceInput
                    source="installation_id"
                    reference="installations"
                    validate={required()}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </FormTab>
            <FormTab label="Colours">
                <ArrayInput source="colours">
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="Colour"
                            source="colour_id"
                            reference="colours"
                            validate={required()}
                        >
                            <SelectInput
                                optionText="id"
                                validate={required()}
                            />
                        </ReferenceInput>
                        <ColorInput
                            label="Value"
                            source="value"
                            picker="Sketch"
                            validate={(required(), validateColour)}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Images">
                <ArrayInput source="images">
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="Image"
                            source="image_id"
                            reference="images"
                            validate={required()}
                        >
                            <SelectInput
                                optionText="id"
                                validate={required()}
                            />
                        </ReferenceInput>
                        <Base64ImageInput label="Value" source="value" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    );
};

export const CustomizationsCreate = (props) => (
    <Create {...props}>
        <CustomizationForm {...props} />
    </Create>
);

export const CustomizationsEdit = (props) => (
    <PessimisticEdit {...props}>
        <CustomizationForm {...props} />
    </PessimisticEdit>
);
