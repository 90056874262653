import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

import { ImagesList } from "./list";
import { ImagesCreate, ImagesEdit } from "./edit";

export default {
    icon: PhotoLibraryIcon,
    list: ImagesList,
    create: ImagesCreate,
    edit: ImagesEdit,
};
