import BuildIcon from '@material-ui/icons/Build';

import { InstallationsList } from './list';
import { InstallationsCreate, InstallationsEdit } from './edit';


export default {
    icon: BuildIcon,
    list: InstallationsList,
    create: InstallationsCreate,
    edit: InstallationsEdit,
};
