import React from "react";
import { Datagrid, Responsive, SimpleList, TextField } from "react-admin";
import { SearchFilter } from "components/search_filter";
import { PessimisticList } from "components/pessimistic_list";

export const DeviceTypesList = (props) => (
    <PessimisticList {...props} filters={<SearchFilter />}>
        <Responsive
            small={
                <SimpleList
                    primaryText={(record) => record.name}
                    secondaryText={(record) => record.id}
                />
            }
            medium={
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="name" />
                </Datagrid>
            }
        />
    </PessimisticList>
);
