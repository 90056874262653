import TextFieldsIcon from '@material-ui/icons/TextFields';

import { PhrasesList } from './list';
import { PhrasesCreate, PhrasesEdit } from './edit';


export default {
    icon: TextFieldsIcon,
    list: PhrasesList,
    create: PhrasesCreate,
    edit: PhrasesEdit,
};
