import React from "react";
import {
    useRecordContext,
    Create,
    FormTab,
    ReferenceManyField,
    SimpleForm,
    TabbedForm,
    TextInput,
    required,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { PessimisticEdit } from "components/pessimistic_edit";
import { validateColour } from "utils/validators";
import { PhrasesGrid } from "resources/phrases/list";

const Form = (props) => (
    <>
        {props.record.id && <TextInput source="id" disabled={true} />}
        <br />
        <TextInput source="label" validate={required()} />
        <ColorInput
            source="colour"
            picker="Sketch"
            validate={[required(), validateColour]}
        />
    </>
);

const FilteredPhrases = (props) => {
    const record = useRecordContext(props);

    return (
        <ReferenceManyField
            fullWidth
            reference="phrases"
            filter={{
                extraFilterParams: {
                    phrase_tags: record.id,
                },
            }}
        >
            <PhrasesGrid />
        </ReferenceManyField>
    );
};

export const PhraseTagsCreate = (props) => (
    <Create {...props}>
        <SimpleForm {...props}>
            <Form {...props} />
        </SimpleForm>
    </Create>
);

export const PhraseTagsEdit = (props) => {
    return (
        <PessimisticEdit {...props}>
            <TabbedForm>
                <FormTab label="General">
                    <Form {...props} />
                </FormTab>
                <FormTab label="Phrases">
                    <FilteredPhrases {...props} />
                </FormTab>
            </TabbedForm>
        </PessimisticEdit>
    );
};
