import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { validateColour } from "utils/validators";
import { PessimisticEdit } from "components/pessimistic_edit";

const ColourForm = (props) => (
    <SimpleForm {...props}>
        <TextInput
            source="id"
            validate={required()}
            disabled={props.record.id}
        />
        <TextInput source="description" multiline maxlength={255} />
        <ColorInput
            label="Default value"
            source="default_value"
            picker="Sketch"
            validate={[required(), validateColour]}
        />
    </SimpleForm>
);

export const ColoursCreate = (props) => (
    <Create {...props}>
        <ColourForm {...props} />
    </Create>
);

export const ColoursEdit = (props) => (
    <PessimisticEdit {...props}>
        <ColourForm {...props} />
    </PessimisticEdit>
);
