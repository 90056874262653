import React from "react";
import {
    useRecordContext,
    Create,
    Edit,
    FormTab,
    ReferenceManyField,
    SimpleForm,
    TabbedForm,
    TextInput,
    required,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";

import { validateColour } from "utils/validators";
import { FlagsGrid } from "resources/feature_flags/list";

const Form = (props) => (
    <>
        {props.record.id && <TextInput source="id" disabled={true} />}
        <br />
        <TextInput source="label" validate={required()} />
        <ColorInput
            source="colour"
            picker="Sketch"
            validate={[required(), validateColour]}
        />
    </>
);

const FilteredFlags = (props) => {
    const record = useRecordContext(props);

    return (
        <ReferenceManyField
            fullWidth
            reference="feature_flags"
            filter={{
                extraFilterParams: {
                    flag_tags: record.id,
                },
            }}
        >
            <FlagsGrid />
        </ReferenceManyField>
    );
};

export const FlagTagsCreate = (props) => (
    <Create {...props}>
        <SimpleForm {...props}>
            <Form {...props} />
        </SimpleForm>
    </Create>
);

export const FlagTagsEdit = (props) => {
    return (
        <Edit {...props}>
            <TabbedForm>
                <FormTab label="General">
                    <Form {...props} />
                </FormTab>
                <FormTab label="Flags">
                    <FilteredFlags {...props} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
