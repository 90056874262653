import React from "react";
import {
    Datagrid,
    ReferenceField,
    ReferenceInput,
    Responsive,
    SelectInput,
    SimpleList,
    TextField,
} from "react-admin";
import { SearchFilter } from "components/search_filter";
import { PessimisticList } from "components/pessimistic_list";

const MyFilter = (props) => (
    <SearchFilter {...props}>
        <ReferenceInput source="installation_id" reference="installations">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </SearchFilter>
);

export const CustomizationsList = (props) => (
    <PessimisticList {...props} filters={<MyFilter />}>
        <Responsive
            small={<SimpleList primaryText={(record) => record.name} />}
            medium={<CustomizationsGrid />}
        />
    </PessimisticList>
);
export const CustomizationsGrid = (props) => {
    return (
        <Datagrid rowClick="edit" {...props}>
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceField source="installation_id" reference="installations">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    );
};
