import React from "react";
import { ChipField } from "react-admin";
import PropTypes from "prop-types";

const resolve = (obj, path) => {
    return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
    }, obj);
};

export const ColouredChipField = ({ record, colourSource, ...props }) => {
    let colour = resolve(record, colourSource);
    if (colour === null) {
        colour = "white";
    }

    const chipStyle = {
        background: colour,
    };

    return <ChipField style={chipStyle} record={record} {...props} />;
};

ColouredChipField.propTypes = {
    addLabel: PropTypes.bool,
};

ColouredChipField.defaultProps = { addLabel: true };
