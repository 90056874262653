import React from "react";
import { Admin, Resource, Layout } from "react-admin";
import { fetchUtils } from "ra-core";
import TranslateIcon from "@material-ui/icons/Translate";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory as createHistory } from "history";
import { apiUrl } from "utils/api";

import authProvider from "./authProvider";
import restDataProvider from "./dataProvider";
// resources
import {
    colours,
    images,
    customizations,
    device_types,
    installations,
    languages,
    phrases,
    phrase_tags,
    translations,
    feature_flags,
    flag_tags,
    feature_flag_rules,
} from "./resources";
import { TranslatorList } from "./resources/translator/list";
import routes from "./routes";

const history = createHistory();

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem("token");
    options.headers.set("Authorization", `Token ${token}`);
    return fetchUtils.fetchJson(url, options);
};
const dataProvider = restDataProvider(
    apiUrl(),
    httpClient
);

Sentry.init({
    dsn: "https://bf6c751f93e44b18b357a8986b883dcf@o303183.ingest.sentry.io/5905483",
    tracesSampleRate: 0,
    integrations: [
        new Integrations.BrowserTracing(),
        new Integrations.BrowserTracing({
            // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
            routingInstrumentation:
                Sentry.reactRouterV5Instrumentation(history),
        }),
    ],
});

const App = () => (
    <Admin
        customRoutes={routes}
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={(props) => <Layout {...props} />}
        history={history}
    >
        <Resource name="installations" {...installations} />
        <Resource name="customizations" {...customizations} />
        <Resource name="device_types" {...device_types} />

        <Resource name="colours" {...colours} />
        <Resource name="images" {...images} />

        <Resource name="feature_flags" {...feature_flags} />
        <Resource name="flag_tags" {...flag_tags} />
        <Resource name="feature_flag_rules" {...feature_flag_rules} />

        <Resource name="languages" {...languages} />
        <Resource name="phrases" {...phrases} />
        <Resource name="phrase_tags" {...phrase_tags} />
        <Resource name="translations" {...translations} />
        <Resource
            name="translator/translatable"
            icon={TranslateIcon}
            list={TranslatorList}
            options={{ label: "Translator" }}
        />
    </Admin>
);
export default App;
