import React from "react";
import {
    Create,
    FormTab,
    ReferenceManyField,
    ReferenceArrayInput,
    SimpleForm,
    TabbedForm,
    TextInput,
    required,
    maxLength,
    AutocompleteArrayInput,
} from "react-admin";
import { TranslationsGrid } from "resources/translations/list";
import { validateICUID } from "utils/validators";
import { PessimisticEdit } from "components/pessimistic_edit";

const PhraseForm = (props) => (
    <>
        <TextInput
            source="id"
            disabled={!!props.record.id}
            validate={[required(), validateICUID, maxLength(255)]}
        />
        <br />
        <TextInput source="phrase" multiline={true} validate={required()} />
        <ReferenceArrayInput source="tags" reference="phrase_tags">
            {/*<SelectArrayInput optionText="label" />*/}
            <AutocompleteArrayInput optionText="label" />
        </ReferenceArrayInput>
    </>
);

export const PhrasesCreate = (props) => (
    <Create {...props}>
        <SimpleForm {...props}>
            <PhraseForm {...props} />
        </SimpleForm>
    </Create>
);

export const PhrasesEdit = (props) => (
    <PessimisticEdit {...props}>
        <TabbedForm>
            <FormTab label="General">
                <PhraseForm {...props} />
            </FormTab>
            <FormTab label="Translations">
                <ReferenceManyField
                    fullWidth
                    reference="translations"
                    target="phrase_id"
                >
                    <TranslationsGrid />
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </PessimisticEdit>
);
