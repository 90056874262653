import React from "react";
import {
    SimpleForm,
    BooleanInput,
    Create,
    FormTab,
    ReferenceManyField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    TabbedForm,
    TextInput,
    required,
} from "react-admin";
import { FeatureFlagRulesGrid } from "resources/feature_flag_rules/list";
import { PessimisticEdit } from "components/pessimistic_edit";

const FeatureFlagForm = (props) => (
    <div>
        <TextInput
            source="id"
            validate={required()}
            disabled={!!props.record.id}
        />
        <br />
        <TextInput source="name" maxlength={255} />
        <BooleanInput source="default_value" />
        <ReferenceArrayInput source="tags" reference="flag_tags">
            <AutocompleteArrayInput optionText="label" />
        </ReferenceArrayInput>
    </div>
);

export const FeatureFlagsCreate = (props) => (
    <Create {...props}>
        <SimpleForm {...props}>
            <FeatureFlagForm {...props} />
        </SimpleForm>
    </Create>
);

export const FeatureFlagsEdit = (props) => (
    <PessimisticEdit {...props}>
        <TabbedForm>
            <FormTab label="General">
                <FeatureFlagForm {...props} />
            </FormTab>
            <FormTab label="Rules">
                <ReferenceManyField
                    fullWidth
                    reference="feature_flag_rules"
                    target="feature_flag_id"
                >
                    <FeatureFlagRulesGrid />
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </PessimisticEdit>
);
