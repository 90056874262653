import PaletteIcon from "@material-ui/icons/Palette";

import { ColoursList } from "./list";
import { ColoursCreate, ColoursEdit } from "./edit";

export default {
    icon: PaletteIcon,
    list: ColoursList,
    create: ColoursCreate,
    edit: ColoursEdit,
};
