import { apiUrl } from "utils/api";

var refreshIntervalID = null;

const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");

    clearInterval(refreshIntervalID);
    refreshIntervalID = null;
};

const refreshAPIToken = async (token) => {
    const request = new Request(
        apiUrl() + "/auth/refresh_token",
        {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: "Token " + token,
            }),
        }
    );

    return fetch(request)
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                logout();
                // throw new Error(response.message);
                return Promise.reject("not authenticated");
            }

            return response.json();
        })
        .then(({ data }) => {
            localStorage.setItem("token", data.token);
        });
};

const startTokenRefreshing = () => {
    refreshIntervalID = setInterval(() => {
        refreshAPIToken(localStorage.getItem("token"));
    }, 5 * 60 * 1000);
};

// authentication
export default {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
        const request = new Request(
            apiUrl() + "/auth/login",
            {
                method: "POST",
                body: JSON.stringify({
                    username,
                    password,
                }),
                headers: new Headers({
                    "Content-Type": "application/json",
                }),
            }
        );

        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            logout();
            throw new Error(response.message);
        }

        const { data } = await response.json();
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("token", data.token);
        localStorage.setItem("permissions", JSON.stringify(data.permissions));
        startTokenRefreshing();

        return;
    },

    // Check API error
    checkError: ({ status }) => {
        return status === 401 || status === 403
            ? Promise.reject()
            : Promise.resolve();
    },

    // Check authentication validity
    checkAuth: async () => {
        let user = localStorage.getItem("user");
        let token = localStorage.getItem("token");
        let permissions = localStorage.getItem("permissions");

        if (refreshIntervalID === null) {
            await refreshAPIToken(localStorage.getItem("token"));
            startTokenRefreshing();
        }

        return user && token && permissions
            ? Promise.resolve()
            : Promise.reject();
    },

    logout: logout,

    getIdentity: () => {
        const user = JSON.parse(localStorage.user);
        return {
            id: user.id,
            fullName: `${user.first_name} ${user.last_name}`,
        };
    },

    // authorization
    getPermissions: () => {
        const permissions = localStorage.getItem("permissions");
        return permissions
            ? Promise.resolve(JSON.parse(permissions))
            : Promise.reject();
    },
};
