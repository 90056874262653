import React from "react";
import {
    Datagrid,
    ReferenceArrayField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SingleFieldList,
    Responsive,
    SimpleList,
    TextField,
    BooleanField,
} from "react-admin";
import { SearchFilter } from "components/search_filter";
import { ColouredChipField } from "components/coloured_chip_field";
import { PessimisticList } from "components/pessimistic_list";

const MyFilter = (props) => (
    <SearchFilter {...props}>
        <ReferenceArrayInput
            label="Tags"
            source="`feature_flag_tag_flags`.`tag_id`"
            reference="flag_tags"
        >
            <AutocompleteArrayInput optionText="label" />
        </ReferenceArrayInput>
    </SearchFilter>
);

export const FlagsGrid = (props) => (
    <Datagrid rowClick="edit" {...props}>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField label="Tags" reference="flag_tags" source="tags">
            <SingleFieldList>
                <ColouredChipField source="label" colourSource="colour" />
            </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="default_value" />
    </Datagrid>
);

export const FeatureFlagsList = (props) => (
    <PessimisticList {...props} filters={<MyFilter />}>
        <Responsive
            small={<SimpleList primaryText={(record) => record.name} />}
            medium={<FlagsGrid {...props} />}
        />
    </PessimisticList>
);
