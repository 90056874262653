import LanguageIcon from '@material-ui/icons/Language';

import { LanguagesList } from './list';
import { LanguagesCreate, LanguagesEdit } from './edit';


export default {
    icon: LanguageIcon,
    list: LanguagesList,
    create: LanguagesCreate,
    edit: LanguagesEdit,
};
