import React, { useState, useEffect, useCallback, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {
    AutocompleteInput,
    AutocompleteArrayInput,
    Datagrid,
    DeleteButton,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    SingleFieldList,
    TextField,
    Toolbar,
    required,
    useUpdate,
    useDelete,
    useListContext,
    useNotify,
    useCreate,
} from "react-admin";
import { SearchFilter } from "components/search_filter";
import { ColouredChipField } from "components/coloured_chip_field";
import { TranslationForm } from "resources/translations/edit";
import { useFormState } from "react-final-form";
import { PessimisticList } from "components/pessimistic_list";

const MyFilter = (props) => {
    const { filterValues, setFilters } = useListContext(props);
    const [installationID, setInstallationID] = useState(
        filterValues.scope.installation_id
    );

    // const onInstallationChange = (newInstallationID) => {
    //     if (typeof "newInstallationID" !== "string") {
    //         newInstallationID = newInstallationID?.target?.installation_id; // sometimes this is still event
    //     }
    //
    //     console.log(
    //         "updating installation ID",
    //         newInstallationID,
    //         filterValues
    //     );
    //
    //     setInstallationID(newInstallationID);
    // };

    useEffect(() => {
        if (installationID !== filterValues.scope.installation_id) {
            const newFilterValues = {
                scope: {
                    ...filterValues.scope,
                    customization_id: null,
                },
            };
            setFilters(newFilterValues);
            setInstallationID(filterValues.scope.installation_id);
        }
    }, [filterValues]);

    return (
        <SearchFilter {...props}>
            <ReferenceInput
                label="Language"
                source="scope.language_id"
                reference="languages"
                validate={required()}
                allowEmpty={false}
                alwaysOn
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Installation"
                source="scope.installation_id"
                reference="installations"
                // onChange={onInstallationChange}
                alwaysOn
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            {filterValues?.scope?.installation_id && (
                <ReferenceInput
                    label="Customization"
                    source="scope.customization_id"
                    reference="customizations"
                    filter={{
                        installation_id: filterValues.scope.installation_id,
                    }}
                    alwaysOn
                >
                    <AutocompleteInput optionText="name" />
                </ReferenceInput>
            )}
            <ReferenceInput
                label="Device type"
                source="scope.device_type_id"
                reference="device_types"
                alwaysOn
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
                label="Tags"
                source="extraFilterParams.phrase_tags"
                reference="phrase_tags"
            >
                <AutocompleteArrayInput optionText="label" />
            </ReferenceArrayInput>
        </SearchFilter>
    );
};

const TranslationHandler = ({ translation, afterSave, afterDelete }) => {
    console.log("[translator-step][translation-form]", translation);

    const ToolbarSaveButton = ({ afterSave, ...props }) => {
        const formState = useFormState();
        const notify = useNotify();

        const [create, {loading: createLoading}] = useCreate("translations");
        const [update, {loading: updateLoading}] = useUpdate("translations", formState.values.id);

        let isCreating = props.record.id == null;
        let action = isCreating ? create : update;

        // const { basePath, redirect } = props;
        // get values from the form

        const handleClick = useCallback(() => {
            // call dataProvider.create() manually
            action(
                {
                    payload: {
                        data: { ...formState.values, average_note: 10 },
                    },
                },
                {
                    onSuccess: ({ data: newRecord }) => {
                        notify("ra.notification.created", "info", {
                            smart_count: 1,
                        });
                        afterSave(newRecord);
                    },
                }
            );
        }, [action, notify, formState, afterSave]);

        return (
            <SaveButton
                {...props}
                disabled={updateLoading || createLoading}
                variant="contained"
                handleSubmitWithRedirect={handleClick}
            />
        );
    };

    const ToolbarDeleteButton = ({ afterDelete, ...props }) => {
        const [deleteOne, { loading }] = useDelete(
            "translations",
            props.record.id,
            {},
            {
                onSuccess: () => {
                    afterDelete();
                },
            }
        );

        const handleClick = () => {
            console.log(
                "[translator-step][form][delete-button] click handler",
                props
            );
            deleteOne();
        };

        return (
            <DeleteButton
                {...props}
                redirect={false}
                disabled={loading}
                onClick={handleClick}
            >
                Delete
            </DeleteButton>
        );
    };

    const TranslationToolbar = ({ afterSave, ...props }) => (
        <Toolbar {...props}>
            <ToolbarSaveButton
                submitOnEnter={true}
                label="Save"
                variant="text"
                afterSave={afterSave}
                {...props}
            />
            {props.record.id && (
                <ToolbarDeleteButton afterDelete={afterDelete} />
            )}
        </Toolbar>
    );

    return (
        <SimpleForm
            resource="translations"
            record={translation}
            toolbar={
                <TranslationToolbar afterSave={afterSave} redirect={false} />
            }
        >
            <TranslationForm formData={translation} quiet={true} />
        </SimpleForm>
    );
};

const TranslatorAside = ({ record, afterSave, afterDelete, ...props }) => {
    const { filterValues, refetch } = useListContext(props);
    const { scope } = filterValues;

    let translation = null;

    if (
        record &&
        record.scoped_translation &&
        record.scoped_translation.id !== 0
    ) {
        translation = record.scoped_translation;
    } else if (record) {
        translation = {
            phrase_id: record.phrase.id,
            forms: [""],
            ...scope,
        };
    }

    const afterTranslationSaveCallback = () => {
        afterSave();
        refetch();
    };
    const afterTranslationDeleteCallback = () => {
        afterDelete();
        refetch();
    };

    return (
        <Card style={{ width: 400, margin: "0 1em" }}>
            <CardContent>
                {!translation && (
                    <Typography align="center" variant="subtitle1">
                        Choose translation to edit
                    </Typography>
                )}

                {translation && (
                    <TranslationHandler
                        translation={translation}
                        afterSave={afterTranslationSaveCallback}
                        afterDelete={afterTranslationDeleteCallback}
                        scope={scope}
                        {...props}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export const TranslatorList = (props) => {
    const [recordToEdit, setRecordToEdit] = useState(null);

    const onRowClick = (_id, _row, record) => {
        setRecordToEdit(record);
    };

    const afterTranslationUpdate = () => {
        setRecordToEdit(null);
    };

    // const onKeyDown = (event) => {
    //     if (event.type === "keydown" && event.key !== "Escape") {
    //         return;
    //     }
    //
    //     setTranslation(null);
    // };

    const rowStyler = (record, _index) => ({
        backgroundColor:
            record.scoped_translation && record.scoped_translation.id === 0
                ? "#f6e58d"
                : "white",
    });
    return (
        <PessimisticList
            {...props}
            filters={<MyFilter />}
            bulkActionButtons={<pre></pre>}
            filterDefaultValues={{
                scope: { language_id: "en" },
            }}
            aside={
                <TranslatorAside
                    record={recordToEdit}
                    afterSave={afterTranslationUpdate}
                    afterDelete={afterTranslationUpdate}
                />
            }
        >
            <Datagrid rowStyle={rowStyler} rowClick={onRowClick}>
                {/*<NumberField source="id" /> */}
                <TextField source="id" />

                <ReferenceField
                    basePath="/phrases"
                    label="Phrase"
                    source="phrase.id"
                    reference="phrases"
                    allowEmpty={true}
                >
                    <TextField source="phrase" />
                </ReferenceField>

                <ReferenceArrayField
                    label="Tags"
                    source="phrase.tags"
                    reference="phrase_tags"
                >
                    <SingleFieldList>
                        <ColouredChipField
                            source="label"
                            colourSource="colour"
                        />
                    </SingleFieldList>
                </ReferenceArrayField>

                <TextField
                    source="scoped_translation.translation"
                    label="Scoped translation"
                />

                <TextField
                    source="parent_translation.translation"
                    label="Parent translation"
                />
            </Datagrid>
        </PessimisticList>
    );
};
