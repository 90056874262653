import React, { useState, useEffect } from "react";
import { useForm } from "react-final-form";
import {
    AutocompleteInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    SimpleForm,
    TextInput,
    required,
    useDataProvider,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { PessimisticEdit } from "components/pessimistic_edit";

export const TranslationForm = ({ formData, quiet }) => {
    const form = useForm();
    const dataProvider = useDataProvider();
    const [_phrase, setPhrase] = useState(
        formData.phrase ? formData.phrase : null
    );
    const [_language, setLanguage] = useState(
        formData.language ? formData.language : null
    );

    const onPhraseChange = (phraseID) => {
        console.log("[translation-form] phrase change executor: ", phraseID);
        dataProvider
            .getOne("phrases", { id: phraseID })
            .then(({ data }) => {
                setPhrase(data);
            })
            .catch(() => {
                setPhrase(formData.language ? formData.language : null);
            });
    };

    const onLanguageChange = (langID) => {
        console.log("[translation-form] language change executor: ", langID);
        dataProvider
            .getOne("languages", { id: langID })
            .then(({ data }) => {
                setLanguage(data);
            })
            .catch(() => {
                setLanguage(formData.phrase ? formData.phrase : null);
            });
    };

    const updatePriority = () => {
        let priority = 100;
        if (formData.installation_id && formData.installation_id !== "") {
            priority = 200;
        } else if (
            formData.customization_id &&
            formData.customization_id !== ""
        ) {
            priority = 300;
        }
        if (formData.device_type_id && formData.device_type_id !== "") {
            priority += 10;
        }

        form.change("priority", priority);
    };

    const onInstallationChange = () => {
        form.change("customization_id", null);
        updatePriority();
    };

    const customizations_filter = {
        installation_id: formData.installation_id,
    };

    useEffect(() => {
        console.log("[translation-form] phrase change: ", formData.phrase_id);
        onPhraseChange(formData.phrase_id);
    }, [formData.phrase_id]);

    useEffect(() => {
        console.log(
            "[translation-form] language change: ",
            formData.language_id
        );
        onLanguageChange(formData.language_id);
    }, [formData.language_id]);

    return (
        <>
            <Grid container spacing={3}>
                {!quiet && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput
                                source="phrase_id"
                                reference="phrases"
                                validate={[required()]}
                            >
                                <AutocompleteInput
                                    optionValue="id"
                                    optionText="phrase"
                                />
                            </ReferenceInput>
                            <ReferenceInput
                                source="language_id"
                                reference="languages"
                                validate={[required()]}
                            >
                                <AutocompleteInput
                                    optionValue="id"
                                    optionText="name"
                                />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="priority" disabled />

                            <ReferenceInput
                                source="installation_id"
                                reference="installations"
                                allowEmpty
                                onChange={onInstallationChange}
                            >
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput
                                source="customization_id"
                                reference="customizations"
                                filter={customizations_filter}
                                allowEmpty
                                onChange={updatePriority()}
                            >
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput
                                source="device_type_id"
                                reference="device_types"
                                allowEmpty
                                onChange={updatePriority()}
                            >
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    source="translation"
                    multiline={true}
                    validate={required()}
                />
            </Grid>
        </>
    );
};

export const TranslationsCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" fullWidth>
            <FormDataConsumer>
                {(formDataProps) => <TranslationForm {...formDataProps} />}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

export const TranslationsEdit = (props) => (
    <PessimisticEdit {...props}>
        <SimpleForm redirect="list" fullWidth>
            <FormDataConsumer>
                {(formDataProps) => <TranslationForm {...formDataProps} />}
            </FormDataConsumer>
        </SimpleForm>
    </PessimisticEdit>
);
