import React from "react";
import { Datagrid, Responsive, SimpleList } from "react-admin";
import { SearchFilter } from "components/search_filter";
import { ColouredChipField } from "components/coloured_chip_field";
import { PessimisticList } from "components/pessimistic_list";

export const FlagTagsList = (props) => {
    return (
        <PessimisticList {...props} filters={<SearchFilter />}>
            <Responsive
                small={<SimpleList primaryText={(record) => record.label} />}
                medium={
                    <Datagrid rowClick="edit">
                        {/*<TextField source="id" />*/}
                        <ColouredChipField
                            source="label"
                            colourSource="colour"
                        />
                    </Datagrid>
                }
            />
        </PessimisticList>
    );
};
