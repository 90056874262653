import React, { useState, useEffect } from "react";
import { useForm } from "react-final-form";
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    SimpleForm,
    TextInput,
    required,
    useDataProvider,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { PessimisticEdit } from "components/pessimistic_edit";

export const FeatureFlagRulesForm = ({ formData, quiet }) => {
    const form = useForm();
    const dataProvider = useDataProvider();
    const [flag, setFlag] = useState(formData.flag ? formData.flag : null);
    const [language, _setLanguage] = useState(
        formData.language ? formData.language : null
    );

    const setForms = () => {
        if (!flag || !language) {
            return;
        }

        console.log(
            "[feature-flag-rules] setting forms",
            flag.is_plural,
            language.plural_forms
        );
        let forms = flag.is_plural
            ? [...Array(language.plural_forms)].map(() => "")
            : [""];

        if (
            formData?.forms &&
            formData.forms.length !== forms.length &&
            formData.forms.length > 0
        ) {
            forms[0] = formData.forms[0];
        }

        if (
            !formData ||
            !formData.forms ||
            formData.forms.length !== forms.length
        ) {
            form.change("forms", forms);
        }
    };

    const onFlagChange = (flagID) => {
        console.log("[feature-flag-rules] flag change executor: ", flagID);
        // form.change("forms", null);
        dataProvider
            .getOne("feature_flags", { id: flagID })
            .then(({ data }) => {
                setFlag(data);
            })
            .catch(() => {});
    };

    const updatePriority = () => {
        let priority = 100;
        if (formData.installation_id && formData.installation_id !== "") {
            priority = 200;
        } else if (
            formData.customization_id &&
            formData.customization_id !== ""
        ) {
            priority = 300;
        }
        if (formData.device_type_id && formData.device_type_id !== "") {
            priority += 10;
        }

        form.change("priority", priority);
    };

    const onInstallationChange = () => {
        form.change("customization_id", null);
        updatePriority();
    };

    const customizations_filter = {
        installation_id: formData.installation_id,
    };

    useEffect(() => {
        console.log("[feature-flag-rules] flag change: ", formData.flag_id);
        onFlagChange(formData.flag_id);
    }, [formData.flag_id]);

    useEffect(() => {
        setForms();
    }, [language, flag]);

    return (
        <>
            <Grid container spacing={3}>
                {!quiet && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput
                                source="feature_flag_id"
                                reference="feature_flags"
                                validate={[required()]}
                            >
                                <AutocompleteInput
                                    optionValue="id"
                                    optionText="name"
                                />
                            </ReferenceInput>
                            <BooleanInput source="value" />
                            <TextInput
                                source="condition"
                                helperText="variables like user_id, device_id, ip, version can be used. these *must* then be supplied for rule evaluation."
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="priority" disabled />

                            <ReferenceInput
                                source="installation_id"
                                reference="installations"
                                allowEmpty
                                onChange={onInstallationChange}
                            >
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput
                                source="customization_id"
                                reference="customizations"
                                filter={customizations_filter}
                                allowEmpty
                                onChange={updatePriority()}
                            >
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput
                                source="device_type_id"
                                reference="device_types"
                                allowEmpty
                                onChange={updatePriority()}
                            >
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

export const FeatureFlagRulesCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list" fullWidth>
            <FormDataConsumer>
                {(formDataProps) => <FeatureFlagRulesForm {...formDataProps} />}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

export const FeatureFlagRulesEdit = (props) => (
    <PessimisticEdit {...props}>
        <SimpleForm redirect="list" fullWidth>
            <FormDataConsumer>
                {(formDataProps) => <FeatureFlagRulesForm {...formDataProps} />}
            </FormDataConsumer>
        </SimpleForm>
    </PessimisticEdit>
);
